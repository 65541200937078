<template>
  <div
    class="px-4"
    style="border-left: 4px solid #c4c4c4; height: 100%"
    v-if="customer"
  >
    <v-dialog v-model="showEdit" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon>Edit</v-btn>
      </template>

      <v-card class="py-3 px-3">
        <EditContact @reload="reloadResource" />

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <div class="text-center pt-5">
      <v-avatar color="primary" size="80"></v-avatar>
      <p class="contact-name pt-2 mb-0">
        {{
          customer.first_name +
            " " +
            customer.middle_name +
            " " +
            customer.last_name
        }}
      </p>
      <span class="text--secondary">
        {{ customer.email }}
      </span>
      <div>
        <span class="text--secondary"> {{ customer.address }} </span>
      </div>
      <div>
        <span class="text--secondary"> {{ customer.job_title }} </span>
      </div>
      <div>
        <span class="text--secondary"> {{ customer.company.company_name }} </span>
      </div>
      <div>
        <span class="text--secondary"> {{ customer.customer_type.name }} </span>
      </div>
      <div>
        <span class="text--secondary"> {{ customer.number }} </span>
      </div>
    </div>

    <div style="border-bottom: 1px solid #c4c4c4" class="pb-10">
      <v-row class="d-flex justify-center mt-3">
        <div>
          <v-row class="mt-3">
            <template v-for="item in iconButtons">
              <action-buttons
                :icon="item.icon"
                :buttonName="item.buttonName"
                :key="item.buttonName"
                :customer="customer"
                :path="item.path"
              />
            </template>
          </v-row>
        </div>
      </v-row>
    </div>

    <div class="">
      <router-view></router-view>
    </div>

    <!-- div -->
  </div>
</template>

<script>
import util from "@/util/util";
import ActionButtons from "./ActionButtons.vue";
// import ContactActivityCard from "./ContactActivityCard.vue";
import EditContact from "./EditContact.vue";

export default {
  components: { ActionButtons, EditContact },
  name: "ContactView",

  data() {
    return {
      dialog: false,
      basic: util.input.basic,
      iconButtons: [],
      showEdit: false,
      customer: null,
    };
  },
  methods: {
    getContact() {
      util.http.get(`customer/${this.$route.params.id}`).then((res) => {
        this.customer = res.data.data;
      });
    },
    setItemValues() {
      this.iconButtons = [
        {
          icon: "more",
          buttonName: "Info",
          path: `/contacts/${this.$route.params.id}/additional-information`,
        },
        {
          icon: "email",
          buttonName: "Notify",
          path: `/contacts/${this.$route.params.id}/notify`,
        },
        {
          icon: "note",
          buttonName: "Note",
          path: `/contacts/${this.$route.params.id}/notes`,
        },
        {
          icon: "work",
          buttonName: "Task",
          path: `/contacts/${this.$route.params.id}/tasks`,
        },
        {
          icon: "tag",
          buttonName: "Tag",
          path: `/contacts/${this.$route.params.id}/tags`,
        },
      ];
    },
    reloadResource() {
      this.showEdit = false;
      this.getContact();
    },
  },
  mounted() {
    this.getContact();
    this.setItemValues();
  },
  watch: {
    "$route.params.id": function() {
      console.log("route changed");
      this.getContact();
      this.setItemValues();
    },
  },
};
</script>

<style scoped>
.contact-name {
  font-size: 24px;
}
</style>
