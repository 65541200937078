<template>
  <div>
    <router-link :to="path" class="text-decoration-none" exact
      ><div class="btn-bg">
        <v-btn depressed class="mx-2 text-center" fab small>
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
        <p class="subtitle-2 font-weight-bold text-center mb-0 ">
          {{ buttonName }}
        </p>
      </div></router-link
    >
    <!-- <button @click="onButtonPress()">hello</button> -->
    <!-- <v-dialog v-model="EmailDialog" max-width="750px">
      <email-form :onClose="onClose" :customer="customer" />
      
    </v-dialog>
    <v-dialog v-model="Notes" max-width="600px">
      <add-notes :customer="customer" />
    </v-dialog> -->
    <!-- <v-dialog v-model="Tags" max-width="600px">
      <AddTags
        @showCreateDialog="showCreateDialog = true"
        :customer="customer"
      />
    </v-dialog>
    <v-dialog v-model="Tasks" max-width="600px">
      <AddTasks />
    </v-dialog>
    <v-dialog v-model="showCreateDialog" max-width="600px">
      <createTag />
    </v-dialog> -->
  </div>
</template>

<script>
// import EmailForm from "./PopupForms/EmailForm.vue";
// import AddNotes from "./PopupForms/AddNotes.vue";
// import AddTags from "./PopupForms/AddTags.vue";
// import AddTasks from "./PopupForms/AddTasks.vue";
// import createTag from "@/components/tags/create";

export default {
  props: ["icon", "buttonName", "customer", "path"],
  components: {
    //  EmailForm,
    //  AddNotes,
    //   AddTags,
    // AddTasks,
    //  createTag
  },

  // data() {
  //   return {
  //     EmailDialog: false,
  //     Notes: false,
  //     Tags: false,
  //     Tasks: false,
  //     showCreateDialog: false,
  //   };
  // },
  methods: {
    // onButtonPress() {
    //   switch (this.buttonName) {
    //     case "Notify":
    //       this.EmailDialog = true;
    //       break;
    //     case "Note":
    //       this.Notes = true;
    //       break;
    //     case "Tag":
    //       this.Tags = true;
    //       break;
    //     case "Task":
    //       this.Tasks = true;
    //   }
    // },

    onClose() {
      this.EmailDialog = false;
    },
  },
};
</script>

<style scoped>
.btn-bg {
  background: #1d2b58;
  margin: 0 1px;
  padding: 10px 15px;
  color: #fff;
  cursor: pointer;
}

.router-link-active {
  background: #fcd5ce !important;
}

.router-link-exact-active {
  color: #e63946 !important;
  background-color: #e63946!important;
}
.btn-bg:hover {
  background: #f1f1f1;
  color: #1d2b58;
}
</style>
